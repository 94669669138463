import i18nIsoCountries from 'i18n-iso-countries';

import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Formik } from 'formik';
import { Box, Collapse, Fade, useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';

import Component from '@framework/decorators/Component';

import { ButtonOutlinedStyled, ButtonStyled } from '@framework/styles/App';

import {
  FormColumnStyled,
  FormRowStyled,
  getMediaByType,
  FormCheckboxStyled,
  FormGenderRadioStyled,
  FormGenderStyled,
  FormGroupedButtonsStyled,
  FormHintStyled,
  FormInputStyled,
  FormSectionStyled,
  FormSelectStyled,
  FormSubheadingStyled,
  FormBirthdayStyled,
  FormPredictionsListStyled,
} from '@framework/styles/Form';

i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/de.json'));

const FormComponent = Component('FormComponent')(props => {
  const {
    models: { FormModel },
    store: { localesStore, helperStore },
    handleBackClick,
    isAdditionalAddressEnabled,
  } = props;

  const theme = useTheme(),
    isMobile = helperStore.isMobile(),
    [keyboardFields, setKeyboardFields] = useState(null);

  const location = useLocation(),
    keyboardIndexedRef = useRef({});

  const getDataByType = useCallback(type => {
    switch (type) {
      case 'store':
        return props.store.stores.reduce((acc, store) => {
          if(store.storeId && store.name) {
            acc.push({
              value: store.storeId,
              label: store.name
            })
          }
          
          return acc;
        }, [ {
            value: '',
            label: 'Lieblingsfiliale',
            inactive: true,
        }]);
      
      case 'language':
        return [
          {
            value: '',
            label: 'Sprache',
            inactive: true,
          },
          {
            value: 'DE',
            label: 'Deutsch',
          },
          {
            value: 'EN',
            label: 'Englisch',
          },
          {
            value: 'FR',
            label: 'Französisch',
          },
          {
            value: 'NL',
            label: 'Niederländische',
          }
        ]
      
      case 'armLength': {
        return [
          {
            value: '',
            label: localesStore.translate('customer.form.label.armLength.default'),
            inactive: true,
          },
          {
            value: '64',
            label: localesStore.translate('customer.form.label.armLength.normal'),
          },
          {
            value: '69',
            label: localesStore.translate('customer.form.label.armLength.longer'),
          },
          {
            value: '58',
            label: localesStore.translate('customer.form.label.armLength.narrow'),
          },
          {
            value: '72',
            label: localesStore.translate('customer.form.label.armLength.extra'),
          },
        ];
      }

      case 'cut': {
        return [
          {
            value: '',
            label: localesStore.translate('customer.form.label.cut.default'),
            inactive: true,
          },
          {
            value: 'super slim',
            label: localesStore.translate('customer.form.label.cut.slim'),
          },
          {
            value: 'body fit',
            label: localesStore.translate('customer.form.label.cut.body'),
          },
          {
            value: 'tailored fit',
            label: localesStore.translate('customer.form.label.cut.tailored'),
          },
          {
            value: 'modern fit',
            label: localesStore.translate('customer.form.label.cut.modern'),
          },
          {
            value: 'comfort fit',
            label: localesStore.translate('customer.form.label.cut.comfort'),
          },
        ];
      }

      case 'size': {
        const size = Array(19)
          .fill('')
          .map((value, index) => ({
            value: value + (36 + index),
            label: value + (36 + index),
          }));

        return [
          {
            value: '',
            label: localesStore.translate('customer.form.label.size.default'),
            inactive: true,
          },
          ...size,
        ];
      }

      case 'countries': {
        const countries = [
          'DE',
          'AT',
          'NL',
          'CH',
          'BE',
          'FR',
          'BG',
          'DK',
          'EE',
          'FI',
          'GR',
          'IE',
          'IT',
          'LV',
          'LT',
          'LU',
          'MT',
          'PL',
          'PT',
          'RO',
          'SE',
          'SK',
          'SI',
          'ES',
          'CZ',
          'HU',
          'CY',
        ];

        const translations = i18nIsoCountries.getNames(localesStore.currentLocale, { select: 'official' });

        return [  {
            value: '',
            label: localesStore.translate('customer.form.label.field.country'),
            inactive: true,
            disabled: true,
          }].concat(countries.map(value => ({
            value: value,
            label: translations[value],
          })));
      }
    }
  }, []);

  const {
    isAdditionalAddressToggled,
    variant,
    predictions,
    attempts,
    Type: { Creation, Guest, Editor },
    verifyForm,
    AutoCompleteField,
    connectGoogleApi,
    requiredFields,
    getFormDataByVariant,
    handleGoogleBlur,
    handleGoogleChoose,
    handleGoogleInput,
    handleSubmitCall,
    handleToggleAdditionalAddress,
    keyboardInterfaceMounted,
    mountForm,
  } = FormModel;
  
  const settings = FormModel.settings ?? {};

  useEffect(() => {
    mountForm();
  }, []);
  useEffect(() => connectGoogleApi(), [attempts]);
  useEffect(() => keyboardInterfaceMounted(keyboardFields), [keyboardFields]);
  useEffect(() => {
    const onBlur = handleGoogleBlur.bind(FormModel);
    document.addEventListener('click', onBlur);

    return () => {
      document.removeEventListener('click', onBlur);
    };
  }, []);

  const handleReferenceAssignation = useCallback(
    node => {
      if (node) {
        keyboardIndexedRef.current[node.dataset.keyboard] = node;

        if (
          (variant === Creation && node.dataset.keyboard === '15') ||
          (variant !== Creation && node.dataset.keyboard === '14')
        ) {
          setKeyboardFields(keyboardIndexedRef.current);
        }
      }
    },
    [keyboardIndexedRef]
  );
  
  const initialValues = getFormDataByVariant(isAdditionalAddressEnabled);

  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      validate={verifyForm}
      onSubmit={(data, { resetForm }) => handleSubmitCall(data, resetForm)}
    >
      {({ values, errors, handleChange, handleBlur, handleSubmit, handleReset }) => (
        <Fragment>
          <FormRowStyled>
            <FormColumnStyled $isMain media={getMediaByType('tablet-main')}>
              <FormRowStyled spacing={2}>
                <FormColumnStyled media={getMediaByType('full')}>
                  <FormGenderStyled error={errors.salutation}>
                    <FormGenderRadioStyled
                      name="salutation"
                      value="MR"
                      rootValue={values.salutation}
                      onChange={handleChange}
                      placeholder={localesStore.translate('placeholder.label.salutation.MR')}
                      required={requiredFields.includes('salutation')}
                    />
                    <FormGenderRadioStyled
                      name="salutation"
                      value="MRS"
                      rootValue={values.salutation}
                      onChange={handleChange}
                      placeholder={localesStore.translate('placeholder.label.salutation.MRS')}
                      required={requiredFields.includes('salutation')}
                    />
                    <FormGenderRadioStyled
                      name="salutation"
                      value="MRX"
                      rootValue={values.salutation}
                      onChange={handleChange}
                      placeholder={localesStore.translate('placeholder.label.salutation.MRX')}
                      required={requiredFields.includes('salutation')}
                    />
                  </FormGenderStyled>
                </FormColumnStyled>
              </FormRowStyled>
              <FormRowStyled spacing={2}>
                <FormColumnStyled media={getMediaByType('full')}>
                  <FormInputStyled
                    name="firstName"
                    ref={handleReferenceAssignation}
                    keyboardIndex="1"
                    maxLength={50}
                    onChange={handleChange}
                    value={values.firstName}
                    placeholder={localesStore.translate('customer.form.label.field.firstName')}
                    required={requiredFields.includes('firstName')}
                    error={errors.firstName}
                  />
                </FormColumnStyled>
              </FormRowStyled>
              <FormRowStyled spacing={2}>
                <FormColumnStyled media={getMediaByType('full')}>
                  <FormInputStyled
                    name="lastName"
                    ref={handleReferenceAssignation}
                    keyboardIndex="2"
                    onChange={handleChange}
                    value={values.lastName}
                    placeholder={localesStore.translate('customer.form.label.field.lastName')}
                    error={errors.lastName}
                    maxLength={50}
                    required={requiredFields.includes('lastName')}
                  />
                </FormColumnStyled>
              </FormRowStyled>
              <FormRowStyled spacing={2}>
                <FormColumnStyled media={getMediaByType('full')}>
                  <FormBirthdayStyled
                    ref={handleReferenceAssignation}
                    label={localesStore.translate('customer.form.label.birthday')}
                    placeholder={localesStore.translate('customer.form.label.field.birthday')}
                    onChange={handleChange}
                    value={values.birthday}
                    error={errors.birthday}
                    required={requiredFields.includes('birthday')}
                  />
                </FormColumnStyled>
              </FormRowStyled>
            </FormColumnStyled>
            <FormColumnStyled $isMain media={getMediaByType('tablet-main')}>
              <FormRowStyled spacing={2}>
                <FormColumnStyled media={getMediaByType('short')}>
                  <FormInputStyled
                    name="postalCode"
                    keyboardIndex="6"
                    maxLength={8}
                    ref={handleReferenceAssignation}
                    onChange={e => handleGoogleInput(e, values, handleChange)}
                    value={values.postalCode}
                    placeholder={localesStore.translate('customer.form.label.field.postalCode')}
                    error={errors.postalCode}
                    required={requiredFields.includes('postalCode')}
                  />
                </FormColumnStyled>
                <FormColumnStyled media={getMediaByType('long')}>
                  <FormInputStyled
                    name="city"
                    keyboardIndex="7"
                    maxLength={30}
                    ref={handleReferenceAssignation}
                    onChange={e => handleGoogleInput(e, values, handleChange)}
                    value={values.city}
                    placeholder={localesStore.translate('customer.form.label.field.city')}
                    error={errors.city}
                    required={requiredFields.includes('city')}
                  />
                </FormColumnStyled>
                {Boolean(predictions.length) && ['city', 'postalCode'].includes(AutoCompleteField) && (
                  <Fade key={AutoCompleteField} in={true}>
                    <FormPredictionsListStyled>
                      {predictions?.map((prediction, index) => (
                        <li key={index} onClick={() => handleGoogleChoose(prediction.place_id, handleChange)}>
                          {prediction.description}
                        </li>
                      ))}
                    </FormPredictionsListStyled>
                  </Fade>
                )}
              </FormRowStyled>
              <FormRowStyled spacing={2}>
                <FormColumnStyled media={getMediaByType('long')}>
                  <FormInputStyled
                    name="street"
                    keyboardIndex="8"
                    ref={handleReferenceAssignation}
                    onChange={e => handleGoogleInput(e, values, handleChange)}
                    value={values.street}
                    placeholder={localesStore.translate('customer.form.label.field.street')}
                    error={errors.street}
                    maxLength={40}
                    required={requiredFields.includes('street')}
                  />
                </FormColumnStyled>
                <FormColumnStyled media={getMediaByType('short')}>
                  <FormInputStyled
                    name="streetNumber"
                    keyboardIndex="9"
                    ref={handleReferenceAssignation}
                    onChange={handleChange}
                    value={values.streetNumber}
                    maxLength={20}
                    placeholder={localesStore.translate('customer.form.label.field.streetNumber')}
                    error={errors.streetNumber}
                    required={requiredFields.includes('streetNumber')}
                  />
                </FormColumnStyled>
                {Boolean(predictions.length) && ['street', 'streetNumber'].includes(AutoCompleteField) && (
                  <Fade key={AutoCompleteField} in={true}>
                    <FormPredictionsListStyled>
                      {predictions?.map((prediction, index) => (
                        <li key={index} onClick={() => handleGoogleChoose(prediction.place_id, handleChange)}>
                          {prediction.description}
                        </li>
                      ))}
                    </FormPredictionsListStyled>
                  </Fade>
                )}
              </FormRowStyled>
              <FormRowStyled spacing={2}>
                <FormColumnStyled media={getMediaByType('full')}>
                  <FormInputStyled
                    name="addressLine1"
                    keyboardIndex="10"
                    maxLength={50}
                    ref={handleReferenceAssignation}
                    onChange={handleChange}
                    value={values.addressLine1}
                    placeholder={localesStore.translate('customer.form.label.field.addressLine1')}
                    required={requiredFields.includes('addressLine1')}
                  />
                </FormColumnStyled>
              </FormRowStyled>
              <FormRowStyled spacing={2}>
                <FormColumnStyled media={getMediaByType('full')}>
                  <FormSelectStyled
                    keyboardIndex="11"
                    name="country"
                    ref={handleReferenceAssignation}
                    data={getDataByType('countries')}
                    value={values.country}
                    onChange={handleChange}
                    quantity={5}
                    error={errors.country}
                    required={requiredFields.includes('country')}
                  />
                </FormColumnStyled>
              </FormRowStyled>
            </FormColumnStyled>
            <FormColumnStyled $isMain $isMerged media={getMediaByType('desktop-main')}>
              <FormRowStyled spacing={2}>
                <FormColumnStyled media={getMediaByType('full')}>
                  <FormInputStyled
                    name="phone"
                    keyboardIndex="12"
                    ref={handleReferenceAssignation}
                    onChange={handleChange}
                    value={values.phone}
                    placeholder={localesStore.translate('customer.form.label.field.phone')}
                    error={errors.phone}
                    required={requiredFields.includes('phone')}
                  />
                </FormColumnStyled>
              </FormRowStyled>
              <FormRowStyled spacing={2}>
                <FormColumnStyled media={getMediaByType('full')}>
                  <FormInputStyled
                    name="addressLine2"
                    keyboardIndex="13"
                    ref={handleReferenceAssignation}
                    onChange={handleChange}
                    value={values.addressLine2}
                    placeholder={localesStore.translate('customer.form.label.field.addressLine2')}
                    error={errors.addressLine2}
                    required={requiredFields.includes('addressLine2')}
                  />
                </FormColumnStyled>
              </FormRowStyled>
              <FormRowStyled spacing={2}>
                <FormColumnStyled media={getMediaByType('full')}>
                  <FormInputStyled
                    name="email"
                    keyboardIndex="14"
                    ref={handleReferenceAssignation}
                    onChange={handleChange}
                    value={values.email}
                    placeholder={localesStore.translate('customer.form.label.field.email')}
                    error={errors.email}
                    disabled={!settings.complaints && variant === Editor || settings.migration}
                    required={requiredFields.includes('email')}
                  />
                </FormColumnStyled>
              </FormRowStyled>
              <FormRowStyled spacing={2}>
                <FormColumnStyled media={getMediaByType('full')}>
                  <FormSelectStyled
                    quantity={5}
                    name="store"
                    keyboardIndex="14"
                    ref={handleReferenceAssignation}
                    data={getDataByType('store')}
                    value={values.store}
                    onChange={handleChange}
                    error={errors.store}
                    required={requiredFields.includes('store')}
                  />
                </FormColumnStyled>
              </FormRowStyled>
            </FormColumnStyled>
          </FormRowStyled>
          {variant !== Guest && (
            <Box
              display="grid"
              gridTemplateColumns={isMobile ? '100%' : 'max-content 1fr'}
              columnGap="10px"
              rowGap="10px"
            >
              <FormSectionStyled>
                <FormSubheadingStyled>
                  {localesStore.translate('customer.form.label.heading.advertise')}
                </FormSubheadingStyled>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    '& > *': { marginBottom: '0 !important' },
                    '& > * + *': { marginLeft: isMobile ? '5px' : '10px' },
                  }}
                >
                  <FormCheckboxStyled
                    name="isEmailContactAllowed"
                    disabled={initialValues.isEmailContactAllowed && (variant === Editor || settings.migration)}
                    placeholder={localesStore.translate('customer.form.label.field.email')}
                    value={values.isEmailContactAllowed}
                    onChange={handleChange}
                  />
                  <FormCheckboxStyled
                    name="isPhoneContactAllowed"
                    placeholder={localesStore.translate('customer.form.label.field.phone')}
                    value={values.isPhoneContactAllowed}
                    onChange={handleChange}
                  />
                  <FormCheckboxStyled
                    name="isSMSMMSContactAllowed"
                    placeholder={localesStore.translate('customer.form.label.field.addressLine2')}
                    value={values.isSMSMMSContactAllowed}
                    onChange={handleChange}
                  />
                </Box>
              </FormSectionStyled>
              <FormSectionStyled>
                <FormSubheadingStyled>
                  {localesStore.translate('customer.form.label.heading.personal')}
                </FormSubheadingStyled>
                <Box
                  display="grid"
                  width="100%"
                  gridTemplateColumns={isMobile ? '100%' : '2fr 2fr 2fr 1fr'}
                  columnGap="20px"
                  rowGap="5px"
                >
                  <FormSelectStyled
                    keyboardIndex="15"
                    name="language"
                    ref={handleReferenceAssignation}
                    data={getDataByType('language')}
                    value={values.language}
                    onChange={handleChange}
                    quantity={5}
                  />
                  <FormSelectStyled
                    keyboardIndex="16"
                    name="armLength"
                    ref={handleReferenceAssignation}
                    data={getDataByType('armLength')}
                    value={values.armLength}
                    onChange={handleChange}
                    quantity={5}
                  />
                  <FormSelectStyled
                    keyboardIndex="17"
                    name="cut"
                    ref={handleReferenceAssignation}
                    data={getDataByType('cut')}
                    value={values.cut}
                    onChange={handleChange}
                    quantity={5}
                  />
                  <FormSelectStyled
                    keyboardIndex="18"
                    name="size"
                    ref={handleReferenceAssignation}
                    data={getDataByType('size')}
                    value={values.size}
                    onChange={handleChange}
                    quantity={5}
                  />
                </Box>
              </FormSectionStyled>
            </Box>
          )}
          {
            !settings.complaints ? (
              <FormSectionStyled gap={0}>
                <FormSubheadingStyled>{localesStore.translate('customer.form.label.heading.data')}</FormSubheadingStyled>
                <Box p="0 20px" sx={{ '& p': { whiteSpace: 'unset !important' } }}>
                  <FormCheckboxStyled
                    name="userAgreementAllowed"
                    placeholder={localesStore.translate('customer.form.label.description.userAgreementAllowed')}
                    value={values.userAgreementAllowed}
                    onChange={handleChange}
                    error={errors.userAgreementAllowed}
                    required
                  />
                </Box>
                {isAdditionalAddressEnabled && (
                  <Collapse in={isAdditionalAddressToggled}>
                    <div>
                      <FormSubheadingStyled>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Box
                            sx={{
                              flexGrow: '1',
                              height: '1px',
                              background: theme.application.palette.darkgray,
                              margin: '0 20px',
                            }}
                          />
                          <span>{localesStore.translate('customer.form.label.heading.additionalAddress')}</span>
                          <Box
                            sx={{
                              flexGrow: '1',
                              height: '1px',
                              background: theme.application.palette.darkgray,
                              margin: '0 20px',
                            }}
                          />
                        </Box>
                      </FormSubheadingStyled>
                      <FormRowStyled>
                        <FormColumnStyled gap={'0 20'} $isMain media={getMediaByType('tablet-main')}>
                          <FormRowStyled spacing={2}>
                            <FormColumnStyled media={getMediaByType('full')}>
                              <FormGenderStyled error={errors.additionalSalutation}>
                                <FormGenderRadioStyled
                                  name="additionalSalutation"
                                  value="MR"
                                  rootValue={values.additionalSalutation}
                                  onChange={handleChange}
                                  placeholder={localesStore.translate('placeholder.label.salutation.MR')}
                                  required={requiredFields.includes('salutation')}
                                />
                                <FormGenderRadioStyled
                                  name="additionalSalutation"
                                  value="MRS"
                                  rootValue={values.additionalSalutation}
                                  onChange={handleChange}
                                  placeholder={localesStore.translate('placeholder.label.salutation.MRS')}
                                  required={requiredFields.includes('salutation')}
                                />
                                <FormGenderRadioStyled
                                  name="additionalSalutation"
                                  value="MRX"
                                  rootValue={values.additionalSalutation}
                                  onChange={handleChange}
                                  placeholder={localesStore.translate('placeholder.label.salutation.MRX')}
                                  required={requiredFields.includes('salutation')}
                                />
                              </FormGenderStyled>
                            </FormColumnStyled>
                          </FormRowStyled>
                          <FormRowStyled spacing={2}>
                            <FormColumnStyled media={getMediaByType('full')}>
                              <FormInputStyled
                                name="additionalFirstName"
                                ref={handleReferenceAssignation}
                                keyboardIndex="19"
                                maxLength={50}
                                onChange={handleChange}
                                value={values.additionalFirstName}
                                placeholder={localesStore.translate('customer.form.label.field.firstName')}
                                error={errors.additionalFirstName}
                                required
                              />
                            </FormColumnStyled>
                          </FormRowStyled>
                          <FormRowStyled spacing={2}>
                            <FormColumnStyled media={getMediaByType('full')}>
                              <FormInputStyled
                                name="additionalLastName"
                                ref={handleReferenceAssignation}
                                keyboardIndex="20"
                                maxLength={50}
                                onChange={handleChange}
                                value={values.additionalLastName}
                                placeholder={localesStore.translate('customer.form.label.field.lastName')}
                                error={errors.additionalLastName}
                                required
                              />
                            </FormColumnStyled>
                          </FormRowStyled>
                        </FormColumnStyled>
                        <FormColumnStyled gap={'0 20'} $isMain media={getMediaByType('tablet-main')}>
                          <FormRowStyled spacing={2}>
                            <FormColumnStyled media={getMediaByType('short')}>
                              <FormInputStyled
                                name="additionalPostalCode"
                                keyboardIndex="21"
                                maxLength={8}
                                ref={handleReferenceAssignation}
                                onChange={e => handleGoogleInput(e, values, handleChange)}
                                value={values.additionalPostalCode}
                                placeholder={localesStore.translate('customer.form.label.field.postalCode')}
                                error={errors.additionalPostalCode}
                                required
                              />
                            </FormColumnStyled>
                            <FormColumnStyled media={getMediaByType('long')}>
                              <FormInputStyled
                                name="additionalCity"
                                keyboardIndex="22"
                                maxLength={30}
                                ref={handleReferenceAssignation}
                                onChange={e => handleGoogleInput(e, values, handleChange)}
                                value={values.additionalCity}
                                placeholder={localesStore.translate('customer.form.label.field.city')}
                                error={errors.additionalCity}
                                required
                              />
                            </FormColumnStyled>
                            {Boolean(predictions.length) &&
                              ['additionalCity', 'additionalPostalCode'].includes(AutoCompleteField) && (
                                <Fade key={AutoCompleteField} in={true}>
                                  <FormPredictionsListStyled>
                                    {predictions?.map((prediction, index) => (
                                      <li
                                        key={index}
                                        onClick={() => handleGoogleChoose(prediction.place_id, handleChange, true)}
                                      >
                                        {prediction.description}
                                      </li>
                                    ))}
                                  </FormPredictionsListStyled>
                                </Fade>
                              )}
                          </FormRowStyled>
                          <FormRowStyled spacing={2}>
                            <FormColumnStyled media={getMediaByType('long')}>
                              <FormInputStyled
                                name="additionalStreet"
                                keyboardIndex="23"
                                maxLength={40}
                                ref={handleReferenceAssignation}
                                onChange={e => handleGoogleInput(e, values, handleChange)}
                                value={values.additionalStreet}
                                placeholder={localesStore.translate('customer.form.label.field.street')}
                                error={errors.additionalStreet}
                                required
                              />
                            </FormColumnStyled>
                            <FormColumnStyled media={getMediaByType('short')}>
                              <FormInputStyled
                                name="additionalStreetNumber"
                                keyboardIndex="24"
                                maxLength={20}
                                ref={handleReferenceAssignation}
                                onChange={handleChange}
                                value={values.additionalStreetNumber}
                                placeholder={localesStore.translate('customer.form.label.field.streetNumber')}
                                error={errors.additionalStreetNumber}
                                required
                              />
                            </FormColumnStyled>
                            {Boolean(predictions.length) &&
                              ['additionalStreet', 'additionalStreetNumber'].includes(AutoCompleteField) && (
                                <Fade key={AutoCompleteField} in={true}>
                                  <FormPredictionsListStyled>
                                    {predictions?.map((prediction, index) => (
                                      <li
                                        key={index}
                                        onClick={() => handleGoogleChoose(prediction.place_id, handleChange, true)}
                                      >
                                        {prediction.description}
                                      </li>
                                    ))}
                                  </FormPredictionsListStyled>
                                </Fade>
                              )}
                          </FormRowStyled>
                          <FormRowStyled spacing={2}>
                            <FormColumnStyled media={getMediaByType('full')}>
                              <FormInputStyled
                                name="additionalAddressLine1"
                                keyboardIndex="25"
                                maxLength={50}
                                ref={handleReferenceAssignation}
                                onChange={handleChange}
                                value={values.additionalAddressLine1}
                                placeholder={localesStore.translate('customer.form.label.field.addressLine1')}
                              />
                            </FormColumnStyled>
                          </FormRowStyled>
                        </FormColumnStyled>
                        <FormColumnStyled gap={'0 20'} $isMain media={getMediaByType('tablet-main')}>
                          <FormRowStyled spacing={2}>
                            <FormColumnStyled media={getMediaByType('full')}>
                              <FormSelectStyled
                                keyboardIndex="26"
                                name="additionalCountry"
                                ref={handleReferenceAssignation}
                                data={getDataByType('countries')}
                                value={values.additionalCountry}
                                onChange={handleChange}
                                error={errors.additionalCountry}
                                quantity={5}
                                required
                              />
                            </FormColumnStyled>
                          </FormRowStyled>
                        </FormColumnStyled>
                      </FormRowStyled>
                    </div>
                  </Collapse>
                )}
              </FormSectionStyled>
            ) : null
          }
         
          <FormHintStyled>{localesStore.translate('placeholder.form.required')}</FormHintStyled>

          <FormGroupedButtonsStyled isAdditionalAddressEnabled={isAdditionalAddressEnabled}>
            {isAdditionalAddressEnabled && (
              <ButtonStyled
                marginRightAuto
                $isActive={!isAdditionalAddressToggled}
                onClick={() => handleToggleAdditionalAddress(handleChange)}
              >
                {localesStore.translate(
                  `customer.form.controls.${isAdditionalAddressToggled ? 'hide' : 'show'}AdditionalAddress`
                )}
              </ButtonStyled>
            )}
            {handleBackClick ? (
              <Fragment>
                <ButtonOutlinedStyled $auto onClick={handleBackClick}>
                  {localesStore.translate('customer.form.controls.back')}
                </ButtonOutlinedStyled>
                <ButtonStyled onClick={handleSubmit}>
                  {localesStore.translate('customer.form.controls.save')}
                </ButtonStyled>
              </Fragment>
            ) : (
              <Fragment>
                <ButtonOutlinedStyled $auto onClick={handleReset}>
                  {localesStore.translate('customer.form.controls.reset')}
                </ButtonOutlinedStyled>
                <ButtonStyled onClick={handleSubmit}>
                  {
                    settings.complaints ? 'Daten erfassen' : (
                      localesStore.translate(
                        'customer.form.controls.' +
                          (location.pathname.includes('cart') || location.pathname.includes('catalog')
                            ? 'continue'
                            : 'submit')
                      )
                    )
                  }
                </ButtonStyled>
              </Fragment>
            )}
          </FormGroupedButtonsStyled>
        </Fragment>
      )}
    </Formik>
  );
});

export default inject('store', 'models')(observer(FormComponent));
